import React from 'react'

import './Banner.css'

import banner_line from '../../../assets/svg/banner_line.svg'
import banner_line_mob from '../../../assets/svg/banner_line_mob.svg'
import footfall from '../../../assets/svg/footfall.svg'
import events from '../../../assets/svg/events.svg'
import prizes from '../../../assets/svg/prizes.svg'
import line from '../../../assets/svg/line.svg'

function Banner() {
  return (
    <div className='banner'>
      <div className="banner__container">
        <img src={banner_line} alt="" className='banner_line'/>
        <img src={banner_line_mob} alt="" className='banner_line_mob'/>
        <div className='banner_card bc_1' data-aos="zoom-in" data-aos-delay="50">
          <img src={footfall} alt='' className='banner_card_img' />  
          <img src={footfall} alt='' className='banner_card_bg' />  
          <img src={line} alt='' className='banner_card_line' />
          <div className='banner_card_details'>
            <h4 className='banner_card_info1'>Footfall</h4>
            <h6 className='banner_card_info2'>8000+</h6>
          </div>
        </div>
        <div className='banner_card bc_2' data-aos="zoom-in" data-aos-delay="300">
          <img src={events} alt='' className='banner_card_img' />
          <img src={events} alt='' className='banner_card_bg' />
          <img src={line} alt='' className='banner_card_line' />
          <div className='banner_card_details'>
            <h4 className='banner_card_info1'>Events</h4>
            <h6 className='banner_card_info2'>40+</h6>
          </div>
        </div>
        <div className='banner_card bc_3' data-aos="zoom-in" data-aos-delay="600">
          <img src={prizes} alt='' className='banner_card_img' />
          <img src={prizes} alt='' className='banner_card_bg' />
          <img src={line} alt='' className='banner_card_line' />
          <div className='banner_card_details'>
            <h4 className='banner_card_info1'>Prize Pool</h4>
            <h6 className='banner_card_info2'>4,00,000+</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner