import { lazy } from 'react'

const EventsPage = lazy(() => import('./EventsPage/EventsPage'))
const LandingPage = lazy(() => import('./LandingPage/LandingPage'))
const GalleryPage = lazy(() => import('./GalleryPage/GalleryPage'))
const ContactsPage = lazy(() => import('./ContactsPage/ContactsPage'))
const SchedulesPage = lazy(() => import('./SchedulesPage/schedulesPage'))
const TalksPage = lazy(() => import('./TalksPage/TalksPage'))
const WorkshopsPage = lazy(() => import('./WorkshopsPage/WorkshopsPage'))
const ProfilePage = lazy(() => import('./ProfilePage/ProfilePage'))
const NotFound404Page = lazy(() => import('./404/404'))

export {
    EventsPage,
    GalleryPage,
    ContactsPage,
    LandingPage,
    SchedulesPage,
    WorkshopsPage,
    TalksPage,
    NotFound404Page,
    ProfilePage
}