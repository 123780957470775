import React from 'react'

import './Unmasked.css'

import fazil from '../../../assets/jpg/fazil.jpg'

function Unmasked() {
  return (
    <div className="unmasked">
      <div className="unmasked__container"> 
        <div className='unmasked_left'>
          <h1 className='unmasked__title'>Unmasked</h1>
          <p data-aos="fade-up">
            Prepare to have your mind blown and your perceptions challenged with Unmasked - the ultimate magic and mentalism event! Excel 2022 is proud to present this year's edition of Unmasked, featuring the incredible Fazil Basheer, renowned for his mesmerizing Tricksmania show.
            <br /><br />
            With a reputation for delivering some of the most captivating and mind-bending performances, Fazil Basheer will take you on a journey of mystery, wonder, and amazement. Unmasked is a show that will not only entertain you, but it will also stimulate your curiosity and leave you with a sense of wonder and intrigue.
          </p>
          <h3>10 March 2023</h3>
          <a href="https://rzp.io/l/unmasked" target="_blank" rel="noreferrer">
            <button className='unmk_btn'>Register</button>
          </a>
        </div>
        <div className='unmasked_right'>
          <div className='ur__img_div' data-aos="zoom-in-up" data-aos-delay="100">
            <img src={fazil} alt="" className='ur__img'/>
            <h4>Fazil Basheer</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unmasked