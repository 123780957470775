import ukxpress from  '../assets/logos/partners/ukxpress.jpg'
import twentyfournews from  '../assets/logos/partners/twentyfournews.png'
import DBiz from  '../assets/logos/partners/DBiz.png'
import radiomango from  '../assets/logos/partners/radiomango.svg'

export const partnersData = [
    {
        id: 1,
        name: 'UK Xpress',
        desc: 'Title Partner',
        url: 'https://www.ukxpress.co.uk/',
        logo: ukxpress
    },
    {
        id: 2,
        name: 'DBiz',
        desc: 'Associate Partner',
        url: 'https://www.dbizsolution.com/',
        logo: DBiz
    },
    {
        id: 3,
        name: '24 News',
        desc: 'Media Partner',
        url: 'https://www.twentyfournews.com/',
        logo: twentyfournews
    },
    {
        id: 4,
        name: 'Radio Mango',
        desc: 'Radio Partner',
        url: 'https://www.radiomango.fm/home.html',
        logo: radiomango
    },

]