import React from 'react'

import './CampusAmb.css'

import speaker from '../../../assets/svg/speaker.svg'
import excel_outline from '../../../assets/png/excel_outline.png'

function CampusAmb() {
  return (
    <div className='campusAmb'>
      <div className='campusAmb__container' data-aos="zoom-in-up" data-aos-delay="100">
        <img src={excel_outline} alt="" className="excel_outline" />
        <div className='campusAmb__left'>
          <img src={speaker} alt="" />
        </div>
        <div className='campusAmb__right'>
          <h1>campus <br />ambassador</h1>
          <p>
            Become the face of Excel in your college, bring your peers together, network and get the chance to reap amazing rewards. If you have great interpersonal and communication skills, apply now!
          </p>
          <a href="https://ca.excelmec.org/" target="_blank" rel="noreferrer">
            <button className='campus__btn'>Be an ambassador</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default CampusAmb