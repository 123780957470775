import React from 'react'
import './RegisteredEvents.css'
import RegEvCard from './RegEvCard';

function RegisteredEvents({registeredEvents}) {
  return (
    <div className='registeredEvents'>
      {registeredEvents.length > 0 && (
        <h2>Registered Events</h2>
      )}
      <div className='re__container'>
        {registeredEvents.length > 0 ? registeredEvents.map((rege) => (
          <RegEvCard 
            key={rege.id}
            id={rege.id}
            name={rege.name}
            icon={rege.icon}
            venue={rege.venue}
            category={rege.category}
            datetime={rege.datetime}
            eventType={rege.eventType}
          />
        )) : (
          <h2>No Registered Events</h2>
        ) }
      </div>
    </div>
  )
}

export default RegisteredEvents