import React from 'react'
import { BsInstagram, BsFillHeartFill } from 'react-icons/bs'
import { FaLinkedinIn, FaYoutube, FaFacebookF, FaTwitter } from 'react-icons/fa'

import './Footer.css'

import excel_logo from '../../../assets/svg/excellogo.svg';
import clg_logo from '../../../assets/png/mec_logo.png'

function Footer() {
  return (
    <div className='footer'>
      <div className='footer_container'>
        <div className='logo'>
          <a href='https://www.mec.ac.in' target="_blank" rel='noreferrer'>
            <img src={clg_logo} alt=" " className='clglogo'/>
          </a>
        </div>
        <div className='details'>
          <div className='social_icons'>
            <a href="https://www.facebook.com/excelmec" target="_blank" rel='noreferrer'>
              <FaFacebookF className='socialicon' />
            </a>
            <a href="https://twitter.com/excelmec" target="_blank" rel='noreferrer'>
              <FaTwitter className='socialicon' />
            </a>
            <a href="https://www.instagram.com/excelmec/" target="_blank" rel='noreferrer'>
              <BsInstagram className='socialicon' />
            </a>
            <a href="https://www.linkedin.com/company/excelmec/" target="_blank" rel='noreferrer'>
              <FaLinkedinIn className='socialicon' />
            </a>
            <a href="https://www.youtube.com/excelmec" target="_blank" rel='noreferrer'>
              <FaYoutube className='socialicon' />
            </a>
          </div>
          <p className='footer_text'>made with <span><BsFillHeartFill className='heart_icon' /> </span>excel 2022</p>
        </div>
        <div className='logo excel_logo'>
           <img src={excel_logo} alt=" " className='excellogo'/>
        </div>

      </div>
    </div>
  )
}

export default Footer