import React from 'react'

import './About.css'
import Aos from 'aos'
import mec from './mec__filled.png'
import aevo from './aevo.png'
import { useEffect } from 'react'
function About() {
  useEffect(()=>{
    Aos.init({duration:1100});
  },[])
  return (
    <div className='about'>
      <h2 data-aos='fade-left' className='page__header'>About</h2>
      <div className='about__body'>
        <img className='about__image about__image__mec' src={mec} alt='mec' />
        <img className='about__image about__image__aevo' src={aevo} alt='aevo' />
        <div className='about__content' data-aos='fade-right'>
          <p>
            In 2001, the students of Govt. Model Engineering College founded Excel, a techno-managerial festival like no other. The annually celebrated techfest has steadily hosted students from more than 2000 universities and 1000 institutions across the nation as it has grown exponentially over the years. Excel has always provided opportunities to students in ways they have only dreamt of, and everyone is invited to be a part of that journey to make this festival a grand success. Now, gearing up for its 23rd edition, Excel promises an celebration of technology that’s a bigger and brighter blast than ever.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About