import React from 'react'
import { useState } from 'react'
import {FaChevronCircleLeft, FaChevronCircleRight, FaTimesCircle} from 'react-icons/fa'
import Aos from 'aos'
import About from './About/About'
import './Gallery.css'
import { useEffect } from 'react'

const Gallery = ({ GalleryImages }) => {

  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  useEffect(()=>{
    Aos.init({duration:1100})
  },[])
  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const prevSlide = () => {
    slideNumber === 0 ? setSlideNumber( GalleryImages.length -1 ) : setSlideNumber( slideNumber - 1 )
  }
  const nextSlide = () => {
    slideNumber + 1 === GalleryImages.length ? setSlideNumber(0) : setSlideNumber(slideNumber + 1)
  }

  return (
    <div className='about__gallery'>
      <About />
      <div className='gallery'>
          <h2 data-aos='zoom-in' className='gallery__title page__header'>Gallery</h2>
          <div className='gallery__body'>
            
            {openModal && 
              <div className='gallery__slider'>
                  <FaTimesCircle className='btn__close' onClick={ handleCloseModal } />
                  <FaChevronCircleLeft className='btn__prev' onClick={ prevSlide } />
                  <FaChevronCircleRight className='btn__next' onClick={ nextSlide } />

                <div className='gallery__image__view'>
                  <img src={GalleryImages[slideNumber].src} alt='' />
                </div>
              </div>
            }

            <div className='gallery__grid'>
              {
                GalleryImages && GalleryImages.map((slide, index) => {
                  return(
                    <div className='gallery__image' key = { index } onClick = { () => handleOpenModal(index) }>
                      <img src={ slide.src } alt='' />
                    </div>
                  )
                })
              }
            </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery