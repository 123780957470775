import { Chip } from  '@mui/material';

function FilterChip({name, checked = true,onClick, onDeleteClick}){

  return(
    <>
      <span onClick={onClick}>
        <Chip
          size="small"
          label={name}
          variant="filled"
          sx={{color: checked? "#094C83": "#91CEEA", backgroundColor: checked ? "#99D9F6" : "transparent"}}
          onDelete={onDeleteClick !== null ? (checked ? onDeleteClick : null) : null}
        />
      </span>
    </>
  )  
}

export default FilterChip;
// import useWindowSize from "./useWindowSize"

// export default function WindowSizeComponent() {
//   const { width, height } = useWindowSize()

//   return (
//     <div>
//       {width} x {height}
//     </div>
//   )
// }