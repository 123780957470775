import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { HiMenuAlt2 } from "react-icons/hi";

import AccountHandler from "../../auth/accountHandler";
import "./Navbar.css";
import { accountBackendUrl } from "../../utils/urls";

import home from "../../assets/png/nav/home.png";
import about from "../../assets/png/nav/about.png";
import events from "../../assets/png/nav/events.png";
import contact from "../../assets/png/nav/contact.png";
import competitions from "../../assets/png/nav/competitions.png";
import talks from "../../assets/png/nav/talks.png";
import schedule from "../../assets/png/nav/schedule.png";
import workshops from "../../assets/png/nav/workshops.png";

function Navbar() {
  const [profile, setProfile] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (AccountHandler.isUserLoggedIn()) {
      axios
        .get(`${accountBackendUrl}/profile`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "accessToken"
            )}`,
          },
        })
        .then(
          (response) => {
            // console.log("Navpro:",response)
            setProfile(response.data);
          },
          (error) => {
            // console.log(error);
          }
        );
    }
  }, []);


  const onLoginClick = () => {
    if (!AccountHandler.isUserLoggedIn()) {
      AccountHandler.logInUser();
    }
  };

  if (profile == null) {
    // AccountHandler.asyncGetUserProfile()
    //    .then((resposne) => {

    // if (res != null) setProfile(res);
    // console.log("profile: ",profile)
    //  })
    //  .catch(() => console.log("Failed to fetch profile pic"));
    // console.log(AccountHandler.asyncGetUserProfile());
  }

  return (
    <div className="navbar">
      <div className="navbar__main container">
        <div
          className="nav_hamburger"
          onClick={handleClick}
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <HiMenuAlt2 className="nam_menu_icon" />
        </div>
      </div>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // onBlur={handleClose}
        // onClick={handleClose}
        transitionDuration={200}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          borderRadius: 20,
          background: "transparent",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            margin: 2,
            width: "300px",
            background: "transparent",
            "&::-webkit-scrollbar": { display: "none" },
            "@media screen and (max-width: 600px)": {
              width: "250px",
            },
          },
        }}
      >
        <div className="navbar__mob">
          {/* <div className="navbar_mob_close" onClick={handleClose}>
            <IoClose />
          </div> */}
          <div className="navbar__mobcontents">
            <div
              className="navmob__link"
              onClick={() => {
                navigate("/");
                handleClose();
                window.location.reload();
              }}
            >
              <img src={home} alt="" />
              Home
            </div>
            <div
              className="navmob__link"
              onClick={() => {
                navigate("/about");
                handleClose();
                window.location.reload();
              }}
            >
              <img src={about} alt="" />
              About Us
            </div>
            <div
              className="navmob__link"
              onClick={() => {
                navigate("/competitions");
                handleClose();
                window.location.reload();
              }}
            >
              <img src={competitions} alt="" />
              Competitions
            </div>
            <div
              className="navmob__link"
              onClick={() => {
                navigate("/events");
                handleClose();
                window.location.reload();
              }}
            >
              <img src={events} alt="" />
              Events
            </div>
            <div
              className="navmob__link"
              onClick={() => {
                navigate("/workshops");
                handleClose();
                window.location.reload();
              }}
            >
              <img src={workshops} alt="" />
              Workshops
            </div>
            <div
              className="navmob__link"
              onClick={() => {
                navigate("/talks");
                handleClose();
                window.location.reload();
              }}
            >
              <img src={talks} alt="" />
              Talks
            </div>
            <div
              className="navmob__link"
              onClick={() => {
                navigate("/schedule");
                handleClose();
                window.location.reload();
              }}
            >
              <img src={schedule} alt="" />
              Schedule
            </div>
            <div
              className="navmob__link"
              onClick={() => {
                navigate("/contacts");
                handleClose();
                window.location.reload();
              }}
            >
              <img src={contact} alt="" />
              Contacts
            </div>
            {AccountHandler.isUserLoggedIn() ? (
              <div>
                <button className="nav_btn"               
                  onClick={() => {
                  navigate("/profile");
                  handleClose();
                  window.location.reload();
                }}>
                  Profile
                </button>
              </div>
            ) : (
              <button className="nav_btn" onClick={onLoginClick}>
                Login
              </button>
            )}

            {/* <Link to=""  className="navmob__link" onClick={ onLoginClick}>
              Login
            </Link> */}
          </div>
        </div>
      </Menu>
    </div>
  );
}

export default Navbar;
