import React from "react";
import { useState } from "react";
import CreateTeamCompo from "../CreateTeamCompo/CreateTeamCompo";
import JoinTeamCompo from "../JoinTeamCompo/JoinTeamCompo";
const TeamCreateComponent = ({ isTeam, eventId, setIsTeam, setTab }) => {
  const [isJoinTeam, setIsJoinTeam] = useState(false);
  const [referralId, setReferralId] = useState();
  const [isCreateTeam, setIsCreateTeam] = useState(false);
  if (isTeam) {
    return (
      <div className="teamCreate">
        <input
          placeholder="Enter referral id (optional)"
          type="text"
          value={referralId}
          onChange={(e) => {
            setReferralId(e.target.value);
          }}
        />
        <div className="teamCreate__btns">
          <button
            onClick={() => {
              setIsCreateTeam(true);
              setIsJoinTeam(false);
            }}
          >
            Create team{" "}
          </button>
          <button
            onClick={() => {
              setIsJoinTeam(true);
              setIsCreateTeam(false);
            }}
          >
            Join team{" "}
          </button>
        </div>

        <CreateTeamCompo
          isCreateTeam={isCreateTeam}
          eventId={eventId}
          setIsTeam={setIsTeam}
          referralId={referralId}
          setTab={setTab}
        />
        <JoinTeamCompo
          isJoinTeam={isJoinTeam}
          eventId={eventId}
          referralId={referralId}
          setIsTeam={setIsTeam}
          setTab={setTab}
        />
      </div>
    );
  }
};

export default TeamCreateComponent;
