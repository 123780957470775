import React,{ useState,useEffect} from 'react'
import { Avatar ,Menu} from '@mui/material'
import AccountHandler from '../../auth/accountHandler'
import axios from 'axios'
import { accountBackendUrl } from '../../utils/urls'
import { useNavigate } from 'react-router-dom'
import'../../pages/ProfilePage/ProfilePage.css'
import './ProfileIcon.css'
const ProfileIcon = () => {
    const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const[profile,setProfile] = useState({})
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  
  useEffect(()=>{
    axios.get(`${accountBackendUrl}/profile`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    }).then((response)=>{
      setProfile(response.data);
    },error=>{

    })
  },[])

  const navigate=useNavigate();
if(AccountHandler.isUserLoggedIn()){


  return (
    <div className='custom__profile_icon'>
        <div className="profile__avatar" onClick={handleClick}>
          <Avatar
            sx={{ width: 36, height: 36 }}
            alt="user_image"
            src={profile.picture && profile.picture}
          />
        </div>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transitionDuration={200}
          disableScrollLock={true}
          sx={{
            borderRadius: 20,
            background: "transparent",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              height: "70px",
              width: "150px",
              margin: 2,
              background: "transparent",
            },
          }}
        >
          <div className="logout_div">
            {AccountHandler.isUserLoggedIn()?<button onClick={()=>{
                navigate('/profile')
            }}>Profile</button>:null}
            
          </div>
        </Menu>
    </div>
  )
        }
}

export default ProfileIcon