import React from "react";
import moment from "moment";
import parse from "html-react-parser";
import "./Tabs.css";
import { useState } from "react";
import AccountHandler from "../../../auth/accountHandler";
import RegisterModal from "./RegisterModal";
import TeamCreateComponent from "./TeamCreateComponent";
import ManageTeam from "./ManageTeam";

function CompAbout({ competition, registrations, setTab }) {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [registeredEvents, setRegisteredEvents] = useState(
    window.localStorage.getItem("registeredEvents")
      ? JSON.parse(window.localStorage.getItem("registeredEvents"))
      : registrations
  );

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const competitionRegister = () => {
    if (AccountHandler.isUserLoggedIn()) {
      if (competition.isTeam) {
        setIsTeam(true);
      } else {
        setOpen(true);
      }
    } else {
      AccountHandler.logInUser();
    }
  };
  //   useEffect(() => {
  //   window.addEventListener('storage', () => {
  //     // When local storage changes, dump the list to
  //     // the console.
  //     console.log('Local storage changed!')
  //     console.log(JSON.parse(window.localStorage.getItem('registeredEvents')));
  //   });
  // }, [registeredEvents]);

  const manageTeam = () => {
    setOpen1(true);
  };
  return (
    <div className="compTab">
      <RegisterModal
        open={open}
        setRegisteredEvents={setRegisteredEvents}
        handleClose={handleClose}
        event_id={competition.id}
      />
      {competition.isTeam &&
      registeredEvents?.some((event) => event.id === competition.id) ? (
        <ManageTeam
          open={open1}
          handleClose={handleClose1}
          event_id={competition.id}
        />
      ) : (
        ""
      )}
      <div className="cAbout">
        <p className="ctab__text">{parse(competition?.about)}</p>
        {competition.entryFee > 0 && (
          <p className="ctab__text"> <br />Registration Fee - <b>Rs {competition.entryFee}</b></p>
        )}
      </div>
      {competition.entryFee === 0 &&
        competition.registrationLink &&
        competition.button && (
          <div className="cButton">
            <a
              href={competition.registrationLink}
              target="_blank"
              rel="noreferrer"
            >
              <button className="a_cb_btn">{competition.button}</button>
            </a>
          </div>
        )}

      <div className="cButton">
        {registeredEvents?.some((event) => event.id === competition.id) &&
        competition.isTeam === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <button className="a_cb_btn">Registered</button>
            {competition.entryFee > 0 && (
              <a
                href={competition.registrationLink}
                target="_blank"
                rel="noreferrer"
              >
                <button className="a_cb_btn">Pay Entry Fee</button>
              </a>
            )}
          </div>
        ) : registeredEvents?.some((event) => event.id === competition.id) &&
          competition.isTeam === true ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <button className="a_cb_btn">Team Registered</button>
            {competition.entryFee > 0 && (
              <a
                href={competition.registrationLink}
                target="_blank"
                rel="noreferrer"
              >
                <button className="a_cb_btn">Pay Entry Fee</button>
              </a>
            )}
            <button className="a_cb_btn" onClick={manageTeam}>
              Manage Team
            </button>
          </div>
        ) : competition.registrationEndDate < new Date().toISOString() ? (
          <>
            {competition.registrationOpen && (
              <button className="a_cb_btn">Registration Closed</button>
            )}

            {competition.registrationLink && competition.button ? (
              <a
                href={competition.registrationLink}
                target="_blank"
                rel="noreferrer"
              >
                <button className="a_cb_btn">{competition.button}</button>
              </a>
            ) : null}
          </>
        ) : competition.button && competition.entryFee === 0 ? (
          // <a
          //   href={competition.registrationLink}
          //   target="_blank"
          //   rel="noreferrer"
          // >
          //   <button className="a_cb_btn">{competition.button}</button>
          // </a>
          null
        ) : competition.needRegistration && competition.registrationOpen ? (
          <button onClick={competitionRegister} className="a_cb_btn">
            Register
          </button>
        ) : (
          competition.registrationOpen === false && (
            <button className="a_cb_btn">Registration Closed</button>
          )
        )}
      </div>
      {/* {regClicked?:null} */}

      <TeamCreateComponent
        isTeam={isTeam}
        eventId={competition.id}
        setIsTeam={setIsTeam}
        setTab={setTab}
      />
      <div className="cAbout__footer">
        <div className="cabout_btns">
          <div className="cabout__btn">
            <h5>Venue</h5>
            <h6>{competition.venue}</h6>
          </div>
          <div className="cabout__btn">
            <h5>Date</h5>
            <h6>{moment(competition.datetime).format("MMMM D")}</h6>
          </div>
          <div className="cabout__btn">
            <h5>Time</h5>
            <h6>{moment(competition.datetime).format("LT")}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompAbout;
