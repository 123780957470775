import { useQuery } from "@tanstack/react-query";
import isValidOption from "../utils/isValidOption";
import excelApi from "./api";
import ExcelAPIConfig from "./config";

/** 
    GET /api/events
**/

function getExcelEventsData(eventType = "", eventCategory = "") {
  if (
    eventType !== "" &&
    !isValidOption(eventType, ExcelAPIConfig.CONSTANTS.eventTypes)
  )
    return new Error("Invalid option!");

  if (
    eventCategory !== "" &&
    !isValidOption(eventCategory, ExcelAPIConfig.CONSTANTS.eventCategories)
  )
    return new Error("Invalid option!");

  if (eventType !== "" && eventCategory !== "")
    return () =>
      excelApi.get(
        ExcelAPIConfig.eventWithTypeAndCategoryPath(eventType, eventCategory)
      );
  else if (eventType !== "")
    return () => excelApi.get(ExcelAPIConfig.eventTypePath(eventType));
  else if (eventCategory !== "")
    return () => excelApi.get(ExcelAPIConfig.eventCategoryPath(eventCategory));
  else return () => excelApi.get(ExcelAPIConfig.eventsPath);
}

function getExcelEventDataById(id = "") {
  if (id === "" || isNaN(id)) return new Error("Invalid id!");

  return () => excelApi.get(ExcelAPIConfig.eventByIdPath(id));
}

export function useExcelEventsData(
  options = { eventType: "", eventCategory: "" },
  onSuccess,
  // onError = (err) => toast.error(err.message)
) {
  return useQuery(
    ["excel-events"],
    getExcelEventsData(options.eventType, options.eventCategory),
    {
      onSuccess,
      // onError,
      select: (data) => {
        return data.data;
      },
      staleTime: 30000,
    }
  );
}

export function useExcelEventData(
  option = { id: "" },
  onSuccess,
  // onError = (err) => toast.error(err.message)
) {
  // console.log("useExcelEventData - ", option.id)
  return useQuery(
    [`excel-event=${option.id ?? ""}`],
    getExcelEventDataById(option.id),
    {
      onSuccess,
      // onError,
      select: (data) => {
        return data.data;
      },
      staleTime: 30000,
      enabled: option.id !== "",
      // notifyOnChangeProps: "all"
    }
  );
}

export function useExcelBookmarksData(
  onSuccess,
  // onError = (err) => toast.error(err.message)
) {
  return useQuery(
    ["excel-bookmarks"],
    () =>
      localStorage.getItem("accessToken") &&
      excelApi.get(ExcelAPIConfig.bookmarksPath, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    {
      onSuccess,
      // onError,
      select: (data) => {
        return data?.data;
      },
      staleTime: 30000,
    }
  );
}
