import axios from "axios";
import React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { caBaseUrl, eventsBaseUrl } from "../../../utils/urls";

const JoinTeamCompo = ({
  isJoinTeam,
  eventId,
  setIsTeam,
  setTab,
  referralId,
}) => {
  const joinTeamAndRegister = (e) => {
    e.preventDefault();
    axios
      .post(
        `${eventsBaseUrl}/api/registration`,
        {
          teamId: teamName,
          eventId: eventId,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "accessToken"
            )}`,
          },
        }
      )
      .then(async (response) => {
        // console.log(response);

        if (referralId) {
          await axios.post(`${caBaseUrl}/addTransactionByToken`, {
            eventId: eventId,
            referrerId: referralId,
            accessToken: window.localStorage.getItem("accessToken"),
            point: 10,
          });
        }
        const name=await axios.get(`${eventsBaseUrl}/api/events/${response.data.eventId}`)
        toast.success(`Successuly Joined Team ${name.data.name}`);
        const response1 = await axios.get(`${eventsBaseUrl}/api/events`);
        const events = response1.data;

        const event = events.find(
          (event) => event.id === response.data.eventId
        );

        localStorage.setItem(
          "registeredEvents",
          JSON.stringify([
            ...JSON.parse(localStorage.getItem("registeredEvents")),
            event,
          ])
        );
        setTeamName("");
        setTab(7);
        setTimeout(() => {
          setTab(1);
        }, 200);
        setIsTeam(false);
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.response.data.error);
        setTeamName("");
      });
  };
  const [teamName, setTeamName] = useState("");
  if (isJoinTeam) {
    return (
      <form onSubmit={joinTeamAndRegister} className="register_team_form">
        <input
          placeholder="Enter the Excel Team ID"
          type="number"
          value={teamName}
          onChange={(e) => {
            setTeamName(e.target.value);
          }}
        />
        <button type="submit">Join</button>
      </form>
    );
  }
};

export default JoinTeamCompo;
