import React from 'react'
import parse from 'html-react-parser';

import './Tabs.css'

function CompFormat({ competition }) {
  return (
    <div className='compTab'>
      <div className='cAbout'>
        {parse(competition.format)}
      </div>
    </div>
  )
}

export default CompFormat