import axios from 'axios';
import ExcelAPIConfig from './config';

const excelApi = axios.create({
    baseURL: ExcelAPIConfig.baseURL,
    timeout: 10000,
    timeoutErrorMessage: "Timed out retrieving data!",
});

excelApi.interceptors.request.use((req) => {
    // Do something before request is sent
    // console.log(`${req.method} - ${req.url}`);
    return req;
}, (err) => {
    return Promise.reject(err);
});

excelApi.interceptors.response.use((res) => {
    // Do something with response data
    // console.log(`${res.config.method} ${res.config.url} - ${res.status} ${res.statusText}`, res.data);
    return res;
}, (err) => {
    return Promise.reject(err);
});


export default excelApi;