import "./competitionPage.css";

import {FilterChip, Card, CompetetionCard } from "../../components";

import Aevo from "../../assets/svg/Aevo.svg";
import cstech from "../../assets/svg/cstech.svg";
import nontech from "../../assets/svg/nontech.svg";
import gentech from "../../assets/svg/gentech.svg";
import useWindowSize from "../../hooks/useWindowSize";
import CustomTitle from "../../utils/CustomTitle";

// import { useExcelBookmarksData,} from "../../api/query";
// import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
// import axios from "axios";
// import { eventsBaseUrl } from "../../utils/urls";
import ProfileIcon from "../../components/ProfileIcon/ProfileIcon";
import { event_details } from "../../data/events/event_details";
function CompetitionPage(props) {
  const [eventCategory, setEventCategory] = useState("all");
  const [competitions, setCompetitions] = useState([]);
  // const [registeredEvents, setRegisteredEvents] = useState([]);
  useEffect(() => {
    if (eventCategory === "all") {
      setCompetitions(
        event_details.filter((event) => event.eventType === "competition")
      );
    } else {
      setCompetitions(
        event_details.filter(
          (event) =>
            event.eventType === "competition" &&
            event.category === eventCategory
        )
      );
    }
  }, [eventCategory]);
  // const {
  //   data: competitions,
  //   isError,
  //   // isFetched,
  //   // isFetching,
  //   isLoading,
  //   // isLoadingError,
  //   // isRefetchError,
  //   // isRefetching,
  //   isSuccess,
  // } = useExcelEventsData({ eventType: "competition"});
  // const { data: bookmarksData } = useExcelBookmarksData();
  // const [bookmarkedEvents, setBookmarkedEvents] = useState(
  //   bookmarksData ? bookmarksData : []
  // );
  // const fetchRegisteredEvents = async () => {
  //   await axios
  //     .get(`${eventsBaseUrl}/api/registration`, {
  //       headers: {
  //         Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
  //       },
  //     })
  //     .then(
  //       (response) => {
  //         // console.log("Navreg:",response)
  //         // console.log("Registered Events", response.data);
  //         setRegisteredEvents(response.data);
  //       },
  //       (error) => {
  //         // console.log(error);
  //       }
  //     );
  // };
  // useEffect(() => {
  //   if (registeredEvents.length === 0 && localStorage.getItem("accessToken")) {
  //     fetchRegisteredEvents();
  //   }
  // }, [registeredEvents]);

  // useEffect(() => {
  //   if (bookmarksData) {
  //     setBookmarkedEvents(bookmarksData);
  //   }
  // }, [bookmarksData]);

  // eslint-disable-next-line
  const { width, height } = useWindowSize();

  // if (isError) {
  //   // console.log(error);
  // }

  // if (isSuccess) {
  //   // console.log("Success", competitions);
  // }

  const resetEventCategory = (curr) => {
    if (curr !== "all") setEventCategory("all");
  };

  return (
    <>
      <CustomTitle title="| Competitions" />
      <div className="competitionsPage">
        <ProfileIcon />
        <div className="page__header_div">
          <h1 className="page__header">Competitions</h1>
        </div>
        {width <= 600 && (
          <div className="filter__chip">
            <FilterChip
              name={"All"}
              checked={eventCategory === "all"}
              onClick={resetEventCategory}
              onDeleteClick={null}
            />
            <div className="filter__dot"></div>
            <FilterChip
              name={"CS Tech"}
              checked={eventCategory === "cs_tech"}
              onClick={() => setEventCategory("cs_tech")}
              onDeleteClick={resetEventCategory}
            />
            <div className="filter__dot"></div>
            <FilterChip
              name={"Non-Tech"}
              checked={eventCategory === "non_tech"}
              onClick={() => setEventCategory("non_tech")}
              onDeleteClick={resetEventCategory}
            />
            <div className="filter__dot"></div>
            <FilterChip
              name={"General Tech"}
              checked={eventCategory === "general_tech"}
              onClick={() => setEventCategory("general_tech")}
              onDeleteClick={resetEventCategory}
            />
          </div>
        )}
        <div className="body__container">
          {width > 600 && (
            <div className="competition__catogeory">
              <div className="competition__catogery_inner">
                <div
                  className={
                    eventCategory === "all"
                      ? "competition__catogery_card__selected"
                      : "competition__catogery_card"
                  }
                  onClick={() => setEventCategory("all")}
                >
                  {" "}
                  <CompetetionCard name="All" image={Aevo} />{" "}
                </div>
                <div
                  className={
                    eventCategory === "cs_tech"
                      ? "competition__catogery_card__selected"
                      : "competition__catogery_card"
                  }
                  onClick={() => setEventCategory("cs_tech")}
                >
                  {" "}
                  <CompetetionCard name="CS Tech" image={cstech} />
                </div>
                <div
                  className={
                    eventCategory === "general_tech"
                      ? "competition__catogery_card__selected"
                      : "competition__catogery_card"
                  }
                  onClick={() => {
                    setEventCategory("general_tech");
                  }}
                >
                  <CompetetionCard name="Gen Tech" image={gentech} />{" "}
                </div>
                <div
                  className={
                    eventCategory === "non_tech"
                      ? "competition__catogery_card__selected"
                      : "competition__catogery_card"
                  }
                  onClick={() => {
                    setEventCategory("non_tech");
                  }}
                >
                  <CompetetionCard name="Non Tech" image={nontech} />{" "}
                </div>
              </div>
            </div>
          )}

          <div className="card__container">
            <div className="row">
              {competitions
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .filter((competition) =>
                  eventCategory !== "all"
                    ? competition.category === eventCategory
                    : true
                )
                .map((competition, index) => {
                  return (
                    <div className="col" key={index}>
                      <Card
                        competition={competition}
                        // registeredEvents={registeredEvents}
                        // bookmarkedEvents={bookmarkedEvents}
                        // setBookmarkedEvents={setBookmarkedEvents}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CompetitionPage;
