// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { eventsBaseUrl } from "../../../utils/urls";

import "./Tabs.css";

function CompResults({ competition }) {
  // const [results, setResults] = useState([]);
  // const [isTeam, setIsTeam] = useState(false);
  // useEffect(() => {
  //   axios
  //     .get(`${eventsBaseUrl}/api/result/event/${competition.id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //       },
  //     })
  //     .then(
  //       (response) => {
  //         // console.log("competition response: ", response);
  //         if (response.data.isTeam) {
  //           setIsTeam(true);
  //         }
  //         setResults(response.data.results);
  //       },
  //       (error) => {}
  //     );
  // }, [competition]);
  return (
    <div className="compTab">
      <div className="cAbout">
        {/* {results.length === 0 ? ( */}
          <p>Results not yet published.</p>
        {/* ) : (
          <div>
            <table className="team__table">
              <thead>
                <th>POSITION</th>
                <th>{isTeam ? <span>NAME</span> : <span>NAME</span>}</th>
                {isTeam ? <th>TEAM NAME</th> : null}
              </thead>
              <tbody>
                {results &&
                  results
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .map((result, index) => {
                      return (
                        <tr key={index}>
                          <td>{result.position}</td>
                          <td>{result.name}</td>
                          {isTeam ? <td>{result.teamName}</td> : null}
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default CompResults;
