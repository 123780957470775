import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import { caBaseUrl, eventsBaseUrl } from "../../../utils/urls";
import { toast } from "react-hot-toast";

const RegisterModal = ({
  handleClose,
  open,
  event_id,
  setRegisteredEvents,
}) => {
  const [referralId, setReferralId] = useState();

  const finalRegister = () => {
    axios
      .post(
        `${eventsBaseUrl}/api/registration`,
        {
          eventId: event_id,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "accessToken"
            )}`,
          },
        }
      )
      .then(
        async (response) => {
          // console.log("registration : ", response);
          if (response.status === 200) {
            const name = await axios.get(
              `${eventsBaseUrl}/api/events/${response.data.eventId}`
            );
            toast.success(`Successuly Registered for ${name.data.name}`);
            if (referralId)
              await axios.post(`${caBaseUrl}/addTransactionByToken`, {
                eventId: event_id,
                referrerId: referralId,
                accessToken: window.localStorage.getItem("accessToken"),
                point: 10,
              });

            const response1 = await axios.get(`${eventsBaseUrl}/api/events`);
            const events = response1.data;

            const event = events.find(
              (event) => event.id === response.data.eventId
            );

            localStorage.setItem(
              "registeredEvents",
              JSON.stringify([
                ...JSON.parse(localStorage.getItem("registeredEvents")),
                event,
              ])
            );
            setRegisteredEvents([
              ...JSON.parse(localStorage.getItem("registeredEvents")),
              event,
            ]);
            handleClose();
          }
        },
        (error) => {
          // console.log(error);
          toast.error(error.response.data.error);
          handleClose();
        }
      );
  };
  // useEffect(() => {
  //   window.addEventListener('storage', () => {
  //     // When local storage changes, dump the list to
  //     // the console.
  //     console.log(JSON.parse(window.localStorage.getItem('registeredEvents')));
  //   });
  // }, []);
  return (
    <Dialog
      maxWidth={"md"}
      disableScrollLock={true}
      PaperProps={{ sx: { borderRadius: 0, padding: 0, margin: 0 } }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{ "&::-webkit-scrollbar": { display: "none" }, padding: 0 }}
      >
        <div className="confirm__book_que">
          <input
            placeholder="Enter referral id (optional)"
            type="text"
            value={referralId}
            onChange={(e) => {
              setReferralId(e.target.value);
            }}
            className="referral__input"
          />
          <p>Are you sure you want to register?</p>
          <div className="register_confirm_btn">
            <button onClick={finalRegister}>Yes</button>
            <button
              onClick={() => {
                handleClose();
              }}
            >
              No
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RegisterModal;
