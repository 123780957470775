import React, { useState } from "react";
import axios from "axios";
import { caBaseUrl, eventsBaseUrl } from "../../../utils/urls";
import { toast } from "react-hot-toast";

const CreateTeamCompo = ({
  isCreateTeam,
  eventId,
  setIsTeam,
  setTab,
  referralId,
}) => {
  const createTeamAndRegister = (e) => {
    e.preventDefault();
    axios
      .post(
        `${eventsBaseUrl}/api/Team`,
        {
          name: teamName,
          eventId: eventId,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "accessToken"
            )}`,
          },
        }
      )
      .then(
        async (response) => {
          // console.log(response);
          await axios
            .post(
              `${eventsBaseUrl}/api/registration`,
              {
                teamId: response.data.id,
                eventId: eventId,
              },
              {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "accessToken"
                  )}`,
                },
              }
            )
            .then(async (res) => {
              if (referralId) {
                await axios.post(`${caBaseUrl}/addTransactionByToken`, {
                  eventId: eventId,
                  referrerId: referralId,
                  accessToken: window.localStorage.getItem("accessToken"),
                  point: 10,
                });
              }

              const response1 = await axios.get(`${eventsBaseUrl}/api/events`);
              const events = response1.data;

              const event = events.find(
                (event) => event.id === response.data.eventId
              );

              localStorage.setItem(
                "registeredEvents",
                JSON.stringify([
                  ...JSON.parse(localStorage.getItem("registeredEvents")),
                  event,
                ])
              );
              toast.success(`Successfully created the team ${response.data.name}`);
              setTeamName("");
              setTab(7);
              setTimeout(() => {
                setTab(1);
              }, 200);
              setIsTeam(false);
            })
            .catch((error) => {
              // console.log(error);
              toast.error(error.response.data.error,);

              setTeamName("");
            });
        },
        (error) => {
          // console.log(error);
          toast.error(error.response.data.error);
          setTeamName("");
        }
      );
  };
  const [teamName, setTeamName] = useState("");

  if (isCreateTeam) {
    return (
      <form onSubmit={createTeamAndRegister} className="register_team_form">
        <input
          placeholder="Enter the team name"
          type="text"
          value={teamName}
          onChange={(e) => {
            setTeamName(e.target.value);
          }}
        />
        <button type="submit">Create</button>
      </form>
    );
  }
};

export default CreateTeamCompo;
