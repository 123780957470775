import React, { useState, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Zoom from "@mui/material/Zoom";
import {  RxBookmarkFilled } from "react-icons/rx";
import CompAbout from "./Tabs/CompAbout";
import CompContact from "./Tabs/CompContact";
import CompFormat from "./Tabs/CompFormat";
import CompResults from "./Tabs/CompResults";
import CompRules from "./Tabs/CompRules";
import "./card.css";
// import { useExcelEventData } from "../../api/query";
// import axios from "axios";
// import { eventsBaseUrl } from "../../utils/urls";
// import AccountHandler from "../../auth/accountHandler";
import icon from "../../assets/icons/icon.png";
import Aos from "aos";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={900} ref={ref} {...props} />;
});

function Card(props) {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(1);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  // const {
  //   data: props.competition,
  //   // error,
  //   // isError,
  //   // isFetched,
  //   // isFetching,
  //   isLoading,
  //   // isLoadingError,
  //   // isRefetchError,
  //   // isRefetching,
  //   // isSuccess,
  // } = useExcelEventData({ id: props.competition.id });

  // if (isLoading) {
  //   // console.log("loading event data");
  //   return null;
  // }

  // console.log(props.competition);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // function isFavorite(id) {
  //   let fav = false;
  //   props.bookmarkedEvents?.forEach((element) => {
  //     if (element.id === id) {
  //       fav = true;
  //     }
  //   });
  //   return fav;
  // }

  // const handleFavoriteClick = (id) => {
  //   if (AccountHandler.isUserLoggedIn()) {
  //     axios
  //       .post(
  //         `${eventsBaseUrl}/api/bookmark`,
  //         { eventId: id },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${window.localStorage.getItem(
  //               "accessToken"
  //             )}`,
  //           },
  //         }
  //       )
  //       .then(
  //         (response) => {
  //           // console.log(response);
  //           props.setBookmarkedEvents([...props.bookmarkedEvents, { id: id }]);
  //         },
  //         (error) => {
  //           // console.log(error);
  //         }
  //       );
  //   } else {
  //     AccountHandler.logInUser();
  //   }
  // };

  // const handleUnFavoriteClick = (id) => {
  //   if (AccountHandler.isUserLoggedIn()) {
  //     axios
  //       .delete(`${eventsBaseUrl}/api/bookmark/${id}`, {
  //         headers: {
  //           Authorization: `Bearer ${window.localStorage.getItem(
  //             "accessToken"
  //           )}`,
  //         },
  //       })
  //       .then(
  //         (response) => {
  //           // console.log(response);
  //           props.setBookmarkedEvents(
  //             props.bookmarkedEvents.filter((item) => item.id !== id)
  //           );
  //         },
  //         (error) => {
  //           // console.log(error);
  //         }
  //       );
  //   } else {
  //     AccountHandler.logInUser();
  //   }
  // };

  const renderTab = () => {
    if (tab === 1)
      return (
        <CompAbout
          competition={props.competition}
          // registrations={props.registeredEvents}
          setTab={setTab}
        />
      );
    else if (tab === 2) return <CompFormat competition={props.competition} />;
    else if (tab === 3) return <CompRules competition={props.competition} />;
    else if (tab === 4) return <CompContact competition={props.competition} />;
    else if (tab === 5) return <CompResults competition={props.competition} />;
  };

  return (
    <>
      <div data-aos="zoom-in" className="card_div">
        <div className="card">
          {props.competition.icon === "Microsoft.AspNetCore.Http.FormFile" ? (
            <img
              src={icon}
              alt=""
              className="card__img"
              onClick={handleClickOpen}
            />
          ) : (
            <img
              src={props.competition.icon}
              alt=""
              className="card__img"
              onClick={handleClickOpen}
            />
          )}

          <RxBookmarkFilled className="card__bkmrk" />
        </div>
        <p onClick={handleClickOpen} className="card__text">
          {props.competition.name}
        </p>
      </div>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        disableScrollLock={true}
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: 0,
            margin: 0,
            width: "calc(100% - 32px)",
          },
        }}
        BackdropProps={{
          style: {
            opacity: 0.5,
            background:
              "linear-gradient(90deg, #0C4C82 -13.51%, #0D4F84 -12.59%, #187BA2 5.14%, #1F9BB8 20.99%, #24AFC5 34.24%, #26B6CA 43.28%, #30B9C7 50.27%, #4DBFBE 62.37%, #7ACBAF 78.1%, #B1D89E 94.53%)",
          },
        }}
      >
        <DialogContent
          sx={{ "&::-webkit-scrollbar": { display: "none" }, padding: 0 }}
        >
          <div className="eventsCard__dialog">
            <div className="ecd__left">
              <div className="ecd__left_header">
                {props.competition.icon && (
                  <img
                    src={props.competition.icon}
                    alt=""
                    className="ecd__img"
                  />
                )}
                <div className="ecd__header_text">
                  <h2>{props.competition.name}</h2>
                  {/* {props.competition.needRegistration ? (
                    props.competition.registrationEndDate <
                    new Date().toISOString() ? (
                      <h3>Registration Closed</h3>
                    ) : (
                      <h3>Registration Open</h3>
                    )
                  ) : null} */}
                </div>
              </div>

              <div className="ecd__nav">
                <button
                  className={tab === 1 ? "ecd_btn ecd_btn_selected" : "ecd_btn"}
                  onClick={() => setTab(1)}
                >
                  About
                </button>
                {props.competition?.format && (
                  <button
                    className={
                      tab === 2 ? "ecd_btn ecd_btn_selected" : "ecd_btn"
                    }
                    onClick={() => setTab(2)}
                  >
                    Format
                  </button>
                )}
                {props.competition?.rules && (
                  <button
                    className={
                      tab === 3 ? "ecd_btn ecd_btn_selected" : "ecd_btn"
                    }
                    onClick={() => setTab(3)}
                  >
                    Rules
                  </button>
                )}
                <button
                  className={tab === 4 ? "ecd_btn ecd_btn_selected" : "ecd_btn"}
                  onClick={() => setTab(4)}
                >
                  Contact
                </button>
                <button
                  className={tab === 5 ? "ecd_btn ecd_btn_selected" : "ecd_btn"}
                  onClick={() => setTab(5)}
                >
                  Results
                </button>
              </div>
            </div>
            <div className="ecd__right">
              <h3 className="ecd__name">
                {props.competition.name}
                {props.competition.prizeMoney && (
                  <span>Prize pool - {props.competition.prizeMoney}</span>
                )}
              </h3>
              <div className="ecd__right_content">{renderTab()}</div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default Card;
