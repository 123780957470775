import React from 'react'

import { partnersData } from '../../../data/partnersData'
import { sponsorsData } from '../../../data/sponsorsData'
import './Partners.css'

function Partners() {
  return (
    <div className='partners'>
      <div className='partners__container'>
        <h1 className='partners_title' data-aos="fade-up">
          partners
        </h1>
        <div className="partners__content">
          {partnersData.map((partner) => (
            <a key={partner.id} href={partner.url} className='partner' target="_blank" rel="noreferrer" data-aos="fade-up">
              <img src={partner.logo} alt={partner.name}/>
              <h2>{partner.desc}</h2>
            </a>
          ))}
        </div>
        <div className="sponsors__content">
          {sponsorsData.map((sponsor) => (
            <div key={sponsor.id} href={sponsor.url} className='sponsor' data-aos="fade-up">
              <img src={sponsor.logo} alt={sponsor.name}/>
              <h2>{sponsor.desc}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Partners