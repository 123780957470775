import React,{ useEffect } from 'react'
import moment from "moment";
import Aos from 'aos';

function RegEvCard({ id, name, category, venue, datetime, icon, eventType}) {

useEffect(()=>{
Aos.init({duration:1100});
},[])
  const renderCategory = (cat) => {
    if (cat === 'non_tech')
      return <p>Non Tech</p>
    else if (cat === 'general_tech')
      return <p>Gen Tech</p>
    else if (cat === 'cs_tech')
      return <p>CS Tech</p>
    else 
      return <p>Other</p>
  }

  const renderEventType = (eve) => {
    if (eve === 'general')
      return <p>General</p>
    else if (eve === 'competition')
      return <p>Competition</p>
    else if (eve === 'workshop')
      return <p>Workshop</p>
    else if (eve === 'talk')
      return <p>Talk</p>
    else if (eve === 'conference')
      return <p>Conference</p>
    else 
      return <p>Other</p>
  }



  return (
    <div data-aos="zoom-in" className='regEvCard' key={id}>
      <div className='rec_left'>
        <img src={icon} alt="" />
      </div>
      <div className='rec_right'>
        <h3>{name}</h3>
        <div className="rec_right_section">
          <p>{venue}</p>
          <p>{moment(datetime && datetime).format("MMMM D")}</p>
        </div>
        <div className="rec_right_section">
          {renderCategory(category)}
          {renderEventType(eventType)}
        </div>
      </div>
    </div>
  )
}

export default RegEvCard