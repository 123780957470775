import React from 'react'
// import AnimatedText from 'react-animated-text-content';

import './Home.css'

import home_logo_excel from '../../../assets/svg/home_logo_excel.svg'
import excel_banner from '../../../assets/svg/excel_banner.svg'
import home_v_long from '../../../assets/svg/home_v_long.svg'
import home_v_short from '../../../assets/svg/home_v_short.svg'
import home_aeva from '../../../assets/svg/home_aeva.svg'

import lines from '../../../assets/gif/lines.gif'

function Home() {
  return (
    <div className='home'>
      <img src={home_v_short} alt="" className='home_v_1' />
      <img src={home_v_long} alt="" className='home_v_2' />
      <img src={home_v_short} alt="" className='home_v_3' />
      <img src={home_v_short} alt="" className='home_v_4' />
      <img src={home_v_long} alt="" className='home_v_5' />
      <img src={home_v_long} alt="" className='home_v_6' />
      <div className="home__container">
        <div className='home__left'>
          <div className='home__logo'>
            <img src={home_logo_excel} alt="" className="home_logo_excel" data-aos="zoom-in"/>
            <img src={excel_banner} alt="" className="excel_banner" data-aos="zoom-in-up"/>
          </div>
          <div className='home__date' data-aos="zoom-in-up">
            <div className='home__date_border' />
            <h1>March</h1>
            {/* <AnimatedText
              type="chars" // animate words or chars
              animation={{
                x: '200px',
                y: '-20px',
                scale: 1.1,
                ease: 'ease-in-out',
              }}
              animationType="rifle"
              interval={0.06}
              duration={0.8}
              tag="h2"
              // className="animated-paragraph"
              includeWhiteSpaces
              threshold={0.1}
              rootMargin="20%"
            >
              10,11,12
            </AnimatedText> */}
            {/* <h2>10,11,12</h2> */}
          </div>
        </div>
        <div className='home__right'  data-aos="zoom-in">
          <img src={home_aeva} alt="" className="home_mascot" />
          <img src={lines} alt="" className="home_animation" />
        </div>
      </div>
    </div>
  )
}

export default Home