import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Zoom from "@mui/material/Zoom";
import parse from "html-react-parser";
import Aos from "aos";
// import { useExcelprops.event } from "../../../api/query.js";
import "./EventsCard.css";
import CompAbout from "../../Competitions/Tabs/CompAbout.js";
import CompFormat from "../../Competitions/Tabs/CompFormat.js";
import CompRules from "../../Competitions/Tabs/CompRules.js";
import CompContact from "../../Competitions/Tabs/CompContact.js";
import CompResults from "../../Competitions/Tabs/CompResults.js";
import { useEffect } from "react";
// import axios from "axios";
// import { eventsBaseUrl } from "../../../utils/urls.js";
const Transition = React.forwardRef(function Transition(props, ref) {
  
  return <Zoom timeout={900} ref={ref} {...props} />;
  
});

function EventCard(props) {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(1);
  // const [isComp, setIsComp] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 700 });
  },[]);
  
  // useEffect(() => {
  //   let currentEvent = props.event;
  //   // console.log("current event: ",currentEvent);
  //   axios
  //     .get(`${eventsBaseUrl}/api/result/event/${currentEvent.id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //       },
  //     })
  //     .then(
  //       (response) => {
  //         // console.log("current event response: ", response);
  //         if (response.data.results.length>0) {
  //           setIsComp(true);
  //         }
  //       },
  //       (error) => {}
  //     );
  // }, [props.event]);
  
  // const {
  //   data: props.event,
  //   // error,
  //   // isError,
  //   // isFetched,
  //   // isFetching,
  //   isLoading,
    // isLoadingError,
    // isRefetchError,
    // isRefetching,
    // isSuccess,
  // } = useExcelprops.event({ id: props.event.id });

  //

  // if (isLoading) {
  //   return null;
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderTab = () => {
    if (tab === 1) return <CompAbout competition={props.event} />;
    else if (tab === 2) return <CompFormat competition={props.event} />;
    else if (tab === 3) return <CompRules competition={props.event} />;
    else if (tab === 4) return <CompContact competition={props.event} />;
    else if (tab === 5) return <CompResults competition={props.event} />;
  };

  return (
    <div data-aos="zoom-in" className="eventsCard">
      <div className="ec__imagecontainer" onClick={handleClickOpen}>
        <img
          src={
            props.event.icon
              ? props.event.icon
              : "https://upload.wikimedia.org/wikipedia/commons/4/49/Dell_Inspiron_One_23_Touch_AIO_Desktop_PC.png"
          }
          alt=""
          className="ec__img"
        />
      </div>
      <div className="ec__cardbody" onClick={handleClickOpen}>
        <h4 className="ec__cardtitle">{props.event.name}</h4>
        <p className="ec__desc">{parse(props.event?.about)}</p>
      </div>
      <div className="ec_mob_container" onClick={handleClickOpen}>
        <div className="ec_mob_header">
          <div className="ec__image_mob_container">
            <img
              src={
                props.event.icon
                  ? props.event.icon
                  : "https://upload.wikimedia.org/wikipedia/commons/4/49/Dell_Inspiron_One_23_Touch_AIO_Desktop_PC.png"
              }
              alt=""
              className="ec__img"
            />
          </div>
          <div className="ec_mob_head_content">
            <h4 className="ec__card_mob_title">{props.event.name}</h4>
            {/* <button className='ec__cardbtn'>Register</button> */}
          </div>
        </div>
        <div className="ec_mob_content">
          <p className="ec__desc">{parse(props.event?.about)}</p>
        </div>
      </div>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        fullWidth={true}
        maxWidth="lg"
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: 0,
            margin: 0,
            width: "calc(100% - 32px)",
          },
        }}
        BackdropProps={{
          style: {
            opacity: 0.5,
            background:
              "linear-gradient(90deg, #0C4C82 -13.51%, #0D4F84 -12.59%, #187BA2 5.14%, #1F9BB8 20.99%, #24AFC5 34.24%, #26B6CA 43.28%, #30B9C7 50.27%, #4DBFBE 62.37%, #7ACBAF 78.1%, #B1D89E 94.53%)",
          },
        }}
      >
        <DialogContent
          sx={{ "&::-webkit-scrollbar": { display: "none" }, padding: 0 }}
        >
          <div className="eventsCard__dialog">
            <div className="ecd__left">
              <div className="ecd__left_header">
                {props.event.icon && (
                  <img src={props.event.icon} alt="" className="ecd__img" />
                )}
                <div className="ecd__header_text">
                  <h2>{props.event.name}</h2>
                  {/* {props.event.needRegistration ? (
                    props.event.registrationEndDate <
                    new Date().toISOString() ? (
                      <h3>Registration Closed</h3>
                    ) : (
                      <h3>Registration Open</h3>
                    )
                  ) : null} */}
                </div>
              </div>

              <div className="ecd__nav">
                <button
                  className={tab === 1 ? "ecd_btn ecd_btn_selected" : "ecd_btn"}
                  onClick={() => setTab(1)}
                >
                  About
                </button>
                {props.event?.format && (
                  <button
                    className={
                      tab === 2 ? "ecd_btn ecd_btn_selected" : "ecd_btn"
                    }
                    onClick={() => setTab(2)}
                  >
                    Format
                  </button>
                )}
                {props.event?.rules && (
                  <button
                    className={
                      tab === 3 ? "ecd_btn ecd_btn_selected" : "ecd_btn"
                    }
                    onClick={() => setTab(3)}
                  >
                    Rules
                  </button>
                )}
                <button
                  className={tab === 4 ? "ecd_btn ecd_btn_selected" : "ecd_btn"}
                  onClick={() => setTab(4)}
                >
                  Contact
                </button>
                
                  <button
                    className={
                      tab === 5 ? "ecd_btn ecd_btn_selected" : "ecd_btn"
                    }
                    onClick={() => setTab(5)}
                  >
                    Results
                  </button>
                
              </div>
            </div>
            <div className="ecd__right">
              <h3 className="ecd__name">{props.event.name}</h3>
              <div className="ecd__right_content">{renderTab()}</div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EventCard;
