import cosmos from  '../assets/logos/sponsors/cosmos.svg'
import jubeerich from  '../assets/logos/sponsors/jubeerich.svg'
import keyvalue from  '../assets/logos/sponsors/keyvalue.svg'
import leeway from  '../assets/logos/sponsors/leeway.svg'
import kalyan from  '../assets/logos/sponsors/kalyan.svg'
import bpcl from  '../assets/logos/sponsors/bpcl.jpeg'
import decathlon from  '../assets/logos/sponsors/decathlon.jpeg'
import gdg from  '../assets/logos/sponsors/gdg.jpg'

export const sponsorsData = [
    {
        id: 1,
        name: 'KeyValue',
        logo: keyvalue
    },
    {
        id: 2,
        name: 'Jubeerich',
        logo: jubeerich
    },
    {
        id: 3,
        name: 'Leeway',
        logo: leeway
    },
    {
        id: 4,
        name: 'Cosmos',
        logo: cosmos
    },
    {
        id: 5,
        name: 'Kalyan',
        logo: kalyan
    },
    {
        id: 6,
        name: 'Decathlon',
        logo: decathlon
    },
    {
        id: 7,
        name: 'BPCL',
        logo: bpcl
    },
    {
        id: 8,
        name: 'GDG',
        logo: gdg
    },
]