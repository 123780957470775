import React from 'react'
import parse from 'html-react-parser';

import './Tabs.css'

function CompRules({ competition }) {
  return (
    <div className='compTab'>
      <p className='ctab__list'>
        <ul>
          {parse(competition.rules)}
        </ul>
      </p>
    </div>
  )
}

export default CompRules