import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import AOS from "aos";
import AuthHandler from "./auth/authHandler";
import ScrollToTop from "./utils/ScrollToTop";
import {
  LandingPage,
  ContactsPage,
  EventsPage,
  GalleryPage,
  SchedulesPage,
  TalksPage,
  WorkshopsPage,
  NotFound404Page,
  ProfilePage,
} from "./pages";
import { Navbar } from "./components";
import CompetitionsPage from "./pages/CompetitionPage/CompetitionPage";

import "./ExcelApp.css";

// Configure for all API queries

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // suspense: true, // Experimental feature, might not use. Require other loading components. Also, using suspense feature requires error handling with error boundaries
      // onError: (err) => toast.error(err.message),
    },
  },
});

function ExcelApp() {
  useEffect(() => {
    let index = window.location.href.indexOf("?");
    const searchString = window.location.href.slice(index);
    const urlParams = new URLSearchParams(searchString);
    const refreshToken = urlParams.get("refreshToken");
    if (refreshToken) localStorage.setItem("refreshToken", refreshToken);
    AuthHandler.aysncGetAccessToken(refreshToken);
    if (index >= 0) {
      window.open(window.location.href.slice(0, index), "_self");
    }
  }, []);

  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setTimeout(() => setSpinner(false), 2000);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);

  console.log("%cMade with 💙 by Excel", "color:#3F7CBF; font-size:30px");

  return (
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Toaster
            toastOptions={{
              className: "",
              style: {
                background: "#263743",
                color: "#ffff",
                padding: "10px",
                borderRadius: "8px",
                fontFamily: "Rhizome",
                fontSize: "1rem",
              },
            }}
            position="top-right"
          />
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/competitions" element={<CompetitionsPage />} />
            <Route path="/workshops" element={<WorkshopsPage />} />
            <Route path="/talks" element={<TalksPage />} />
            <Route path="/schedule" element={<SchedulesPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/about" element={<GalleryPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            {/* <Route path="*" element={<Navigate replace to="/" />} /> */}
            <Route path="*" element={<NotFound404Page />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default ExcelApp;