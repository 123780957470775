import React from "react";
import { useExcelBookmarksData } from "../../../api/query";
import RegEvCard from "../RegisteredEvents/RegEvCard";

import "./BookmarkedEvents.css";

function BookmarkedEvents() {
  const { data: bookmarkData } = useExcelBookmarksData();
  return (
    <div className="registeredEvents">
      {bookmarkData?.length > 0 && <h2>Bookmarked Events</h2>}
      <div className="re__container">
        {bookmarkData?.length > 0 ? (
          bookmarkData?.map((rege) => (
            <RegEvCard
              key={rege.id}
              id={rege.id}
              name={rege.name}
              icon={rege.icon}
              venue={rege.venue}
              category={rege.category}
              datetime={rege.datetime}
              eventType={rege.eventType}
            />
          ))
        ) : (
          <h2>No Bookmarked Events</h2>
        )}
      </div>
    </div>
  );
}

export default BookmarkedEvents;
