import React from "react";
import { FiPhoneCall, FiMail } from "react-icons/fi";

import "./Tabs.css";

function CompContact({ competition }) {
  return (
    <div className="compTab">
      <div className="cContact">
        {competition.eventHead1 ? (
          <div className="cContact_card">
            <div className="ccc_left">
              <h1>{competition.eventHead1.name}</h1>
              <p>Event Head</p>
            </div>
            <div className="ccc_right">
              <a
                href={`tel:${competition.eventHead1.phoneNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                <FiPhoneCall className="ccc_icon" />
              </a>
              <a
                href={`mailto:${competition.eventHead1.email}`}
                target="_blank"
                rel="noreferrer"
              >
                <FiMail className="ccc_icon" />
              </a>
            </div>
          </div>
        ) : null}
        {competition.eventHead2 ? (
          <div className="cContact_card">
            <div className="ccc_left">
              <h1>{competition.eventHead2.name}</h1>
              <p>Event Head</p>
            </div>
            <div className="ccc_right">
              <a
                href={`tel:${competition.eventHead2.phoneNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                <FiPhoneCall className="ccc_icon" />
              </a>
              <a
                href={`mailto:${competition.eventHead2.email}`}
                target="_blank"
                rel="noreferrer"
              >
                <FiMail className="ccc_icon" />
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CompContact;
