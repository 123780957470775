import React from 'react'
// import Lottie from "react-lottie";

import spotlight from '../../../assets/svg/spotlight.svg'
// import { useScreenWidth } from "../../../hooks/useScreenWidth";
// import mascot_data from "../../../assets/json/mascot.json";
import './Aeva.css'

function Aeva() {

    // const [mascotSize, setMascotSize] = useState();
    // const size = useScreenWidth();
  
    // const defaultOptions = {
    //   loop: true,
    //   autoplay: true,
    //   animationData: mascot_data,
    //   rendererSettings: {
    //     preserveAspectRatio: "xMidYMid slice",
    //   },
    // };
  
    // useEffect(() => {
    //   if (size > 600) {
    //     setMascotSize(350);
    //   } else if (size > 500) {
    //     setMascotSize(350);
    //   } else if (size > 400) {
    //     setMascotSize(300);
    //   } else {
    //     setMascotSize(200);
    //   }
    // }, [size]);

  return (
    <div className="aeva">
      <div className='aeva_container'>
          <div className='aeva_details'>
              <h2 className='ad_head1' data-aos="fade-up">hi!<br/>i'm <span>aeva</span></h2>
              <p className='ad_para' data-aos="fade-up">
                Meet Excel's bubbly, pointy-eared mascot that is hyper-excited about all things tech and fun, Aeva! Born on 7th October' 22, she is excited to be a part of the tech-fest, unable to sit still due to excitement over informative workshops, 
                on her way to ace every activity, and ready to have a blast at the Proshow with you!
              </p>
          </div>
          <div className='aeva_imagecontainer' data-aos="fade-up">
              <img src={spotlight} alt='' className='ai_image' />
              {/* <Lottie
                options={defaultOptions}
                height={mascotSize}
                width={mascotSize}
                style={{ margin: 0 }}
              /> */}
          </div>
      </div>
    </div>
  )
}

export default Aeva
