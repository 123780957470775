import React, { useEffect,useState } from "react";

import "./Events.css";
import EventCard from "./EventsCard/EventsCard";

// import { useExcelEventsData } from "../../api/query";
import { event_details } from "../../data/events/event_details";
function Events({ setBookmarkedEvents, bookmarkedEvents, registeredEvents }) {
  const[events,setEvents]=useState([])
  useEffect(()=>{
    setEvents(event_details.filter(event => event.eventType === "general"))
  },[])
  // const {
  //   data: eventsData
  //   // error,
  //   // isError,
  //   // isFetched,
  //   // isFetching,
  //   // isLoading,
  //   // isLoadingError,
  //   // isRefetchError,
  //   // isRefetching,
  //   // isSuccess,
  // } = useExcelEventsData({ eventType: "general" });
  return (
    <div className="events">
      <div className="events__body">
        <div className="events__card">
          {events &&
            events.sort((a, b) => a.datetime < b.datetime ? 1 : -1).map((event, index) => {
              return (
                <div className="col" key={index}>
                  <EventCard event={event}
                    registeredEvents={registeredEvents}
                    bookmarkedEvents={bookmarkedEvents}
                    setBookmarkedEvents={setBookmarkedEvents}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Events;
