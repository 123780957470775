import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";

import axios from "axios";
import { FiCopy, FiCheck } from "react-icons/fi";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { eventsBaseUrl, teamBaseUrl } from "../../../utils/urls";
import "./ManageTeam.css";

function ManageTeam({ handleClose, open, event_id }) {
  const [teamId, setTeamId] = useState();
  const [teamName, setTeamName] = useState("");
  const [copied, setCopied] = useState(false)
  const[members,setMembers]=useState([])
  const getTeam = async () => {
    await axios
      .get(`${eventsBaseUrl}/api/events/${event_id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      })
      .then(
        async (response) => {
          if (response.status === 200) {
            // console.log("response", response.data);
            setTeamId(response.data.registration.teamId);

            // console.log("team id", response.data.registration.teamId);
            if (response.data.registration.teamId) {
              await axios
                .get(`${eventsBaseUrl}/api/team/event/${event_id}`, {
                  headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                })
                .then((res) => {
                  // console.log(res.data);
                  res.data.find((team) => {
                    // console.log(team);
                    if (team.id === response.data.registration.teamId) {
                      setTeamName(team.name);
                    }
                    return null;
                  });
                });
            }
          }
        },
        (error) => {
          // console.log(error);
        }
      );
  };
useEffect(()=>{
  axios.post(`${teamBaseUrl}`,{
    teamId:teamId
  }).then((response)=>{
    setMembers(response.data)
  },(error)=>{
    
  })
},[teamId])
  useEffect(() => {
    getTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      maxWidth={"md"}
      disableScrollLock={true}
      PaperProps={{ sx: { borderRadius: 0, padding: 0, margin: 0, minWidth: '300px' } }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{ "&::-webkit-scrollbar": { display: "none" }, padding: 0 }}
      >
        <div className="team_manage_dialog">
          <div className="tmd_header">
            <div className="tmd_name">
              <h2>
                Team Name : 
              </h2>
              <span>{teamName}</span>
            </div>
            <div className="tmd_name">
              <h2>
                Team ID : 
              </h2>
              <span>{teamId}</span>
              
              <CopyToClipboard text={teamId} onCopy={() => setCopied(true)}>
                {copied ? <FiCheck className="tmd_icon"/> : <FiCopy className="tmd_icon"/>}
              </CopyToClipboard>

            </div>
          </div>
          {members.length!==0?(
            <table className="team__table">
            <thead>
              <th>NAME</th>
              <th>EXCEL ID</th>
            </thead>
            <tbody>
              {members.map((member,index)=>{
                return(
                  <tr>
                  <td>{member.name}</td>
                  <td>{member.excelId}</td>
                  </tr>
                )
              })}
              
            </tbody>
          </table>
          ):<p className="no_team_msg">No team members to display</p>}
          
        </div>
        
      </DialogContent>
    </Dialog>
  );
}

export default ManageTeam;
