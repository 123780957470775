import React from 'react'

import './Legacy.css'

import excel2021 from '../../../assets/logos/excel/excel2021.png'
import excel2020 from '../../../assets/logos/excel/excel2020.png'
import excel2019 from '../../../assets/logos/excel/excel2019.png'
import excel2018 from '../../../assets/logos/excel/excel2018.png'
import excel2017 from '../../../assets/logos/excel/excel2017.png'
import excel2016 from '../../../assets/logos/excel/excel2016.png'
import excel2015 from '../../../assets/logos/excel/excel2015.png'

function Legacy() {
  return (
    <div className='legacy'>
      <div className='legacy__container'>
        <div className='landing_section_title legacy_title'>
          <span className='light_blue'>our</span>
          <span>legacy</span>
        </div>
        <div className='legacy_section' data-aos="fade-up">
          <p>
            The nation's second tech festival, Excel was the first of its kind in South India. The students from Govt. Model Engineering College were inspired to create Excel in 2001, which is how this spectacular celebration came to be. Since then, Excel has established itself as a truly unique festival that attracts the interest of gifted students, notable speakers, and reputable companies. From the technical and informative workshops to the non-technical galas, we take enormous pride in organising well-rounded events that enthral you from start to finish.
          </p>

          <div className='legacy_logo_container'>
            <a href='https://2015.excelmec.org/#/' target='_blank' rel='noreferrer' data-aos="fade-up" data-aos-delay="50">
              <img src={excel2015} alt='' className='legacy_logo l2015'/>
            </a>
            <a href='https://2016.excelmec.org/#/' target='_blank' rel='noreferrer' data-aos="fade-up" data-aos-delay="150">
              <img src={excel2016} alt='' className='legacy_logo l2016'/>
            </a>
            <a href='https://2017.excelmec.org/#/' target='_blank' rel='noreferrer' data-aos="fade-up" data-aos-delay="250">
              <img src={excel2017} alt='' className='legacy_logo l2017'/>
            </a>
            <a href='https://2018.excelmec.org' target='_blank' rel='noreferrer' data-aos="fade-up" data-aos-delay="350">
              <img src={excel2018} alt='' className='legacy_logo l2018'/>
            </a>
            <a href='https://2019.excelmec.org' target='_blank' rel='noreferrer' data-aos="fade-up" data-aos-delay="450">
              <img src={excel2019} alt='' className='legacy_logo l2019'/>
            </a>
            <a href='https://2020.excelmec.org' target='_blank' rel='noreferrer' data-aos="fade-up" data-aos-delay="550">
              <img src={excel2020} alt='' className='legacy_logo l2020'/>
            </a>
            <a href='https://2021.excelmec.org' target='_blank' rel='noreferrer' data-aos="fade-up" data-aos-delay="650">
              <img src={excel2021} alt='' className='legacy_logo l2021'/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Legacy